@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root{
    --inherit: inherit;
    --current: currentColor;
    --transparent: transparent;
    --black: #000;
    --white: #fff;
    --slate-50: #f8fafc;
    --slate-100: #f1f5f9;
    --slate-200: #e2e8f0;
    --slate-300: #cbd5e1;
    --slate-400: #94a3b8;
    --slate-500: #64748b;
    --slate-600: #475569;
    --slate-700: #334155;
    --slate-800: #1e293b;
    --slate-900: #0f172a;
    --slate-950: #020617;
    --gray-50: #f9fafb;
    --gray-100: #f2f4f7;
    --gray-200: #eaecf0;
    --gray-300: #d0d5dd;
    --gray-400: #9ca3af;
    --gray-500: #667085;
    --gray-600: #475467;
    --gray-700: #344054;
    --gray-800: #1f2937;
    --gray-900: #101828;
    --gray-950: #0c111d;
    --zinc-50: #fafafa;
    --zinc-100: #f4f4f5;
    --zinc-200: #e4e4e7;
    --zinc-300: #d4d4d8;
    --zinc-400: #a1a1aa;
    --zinc-500: #71717a;
    --zinc-600: #52525b;
    --zinc-700: #3f3f46;
    --zinc-800: #27272a;
    --zinc-900: #18181b;
    --zinc-950: #09090b;
    --neutral-50: #fafafa;
    --neutral-100: #f5f5f5;
    --neutral-200: #e5e5e5;
    --neutral-300: #d4d4d4;
    --neutral-400: #a3a3a3;
    --neutral-500: #737373;
    --neutral-600: #525252;
    --neutral-700: #404040;
    --neutral-800: #262626;
    --neutral-900: #171717;
    --neutral-950: #0a0a0a;
    --stone-50: #fafaf9;
    --stone-100: #f5f5f4;
    --stone-200: #e7e5e4;
    --stone-300: #d6d3d1;
    --stone-400: #a8a29e;
    --stone-500: #78716c;
    --stone-600: #57534e;
    --stone-700: #44403c;
    --stone-800: #292524;
    --stone-900: #1c1917;
    --stone-950: #0c0a09;
    --red-50: #fef2f2;
    --red-100: #fee2e2;
    --red-200: #fecaca;
    --red-300: #fca5a5;
    --red-400: #f87171;
    --red-500: #ef4444;
    --red-600: #dc2626;
    --red-700: #b91c1c;
    --red-800: #991b1b;
    --red-900: #7f1d1d;
    --red-950: #450a0a;
    --orange-50: #fff7ed;
    --orange-100: #ffedd5;
    --orange-200: #fed7aa;
    --orange-300: #fdba74;
    --orange-400: #fb923c;
    --orange-500: #f97316;
    --orange-600: #ea580c;
    --orange-700: #c2410c;
    --orange-800: #9a3412;
    --orange-900: #7c2d12;
    --orange-950: #431407;
    --amber-50: #fffbeb;
    --amber-100: #fef3c7;
    --amber-200: #fde68a;
    --amber-300: #fcd34d;
    --amber-400: #fbbf24;
    --amber-500: #f59e0b;
    --amber-600: #d97706;
    --amber-700: #b45309;
    --amber-800: #92400e;
    --amber-900: #78350f;
    --amber-950: #451a03;
    --yellow-50: #fefce8;
    --yellow-100: #fef9c3;
    --yellow-200: #fef08a;
    --yellow-300: #fde047;
    --yellow-400: #facc15;
    --yellow-500: #eab308;
    --yellow-600: #ca8a04;
    --yellow-700: #a16207;
    --yellow-800: #854d0e;
    --yellow-900: #713f12;
    --yellow-950: #422006;
    --lime-50: #f7fee7;
    --lime-100: #ecfccb;
    --lime-200: #d9f99d;
    --lime-300: #bef264;
    --lime-400: #a3e635;
    --lime-500: #84cc16;
    --lime-600: #65a30d;
    --lime-700: #4d7c0f;
    --lime-800: #3f6212;
    --lime-900: #365314;
    --lime-950: #1a2e05;
    --green-50: #f0fdf4;
    --green-100: #dcfce7;
    --green-200: #bbf7d0;
    --green-300: #86efac;
    --green-400: #4ade80;
    --green-500: #22c55e;
    --green-600: #16a34a;
    --green-700: #15803d;
    --green-800: #166534;
    --green-900: #14532d;
    --green-950: #052e16;
    --emerald-50: #ecfdf5;
    --emerald-100: #d1fae5;
    --emerald-200: #a7f3d0;
    --emerald-300: #6ee7b7;
    --emerald-400: #34d399;
    --emerald-500: #10b981;
    --emerald-600: #059669;
    --emerald-700: #047857;
    --emerald-800: #065f46;
    --emerald-900: #064e3b;
    --emerald-950: #022c22;
    --teal-50: #f0fdfa;
    --teal-100: #ccfbf1;
    --teal-200: #99f6e4;
    --teal-300: #5eead4;
    --teal-400: #2dd4bf;
    --teal-500: #14b8a6;
    --teal-600: #0d9488;
    --teal-700: #0f766e;
    --teal-800: #115e59;
    --teal-900: #134e4a;
    --teal-950: #042f2e;
    --cyan-50: #ecfeff;
    --cyan-100: #cffafe;
    --cyan-200: #a5f3fc;
    --cyan-300: #67e8f9;
    --cyan-400: #22d3ee;
    --cyan-500: #06b6d4;
    --cyan-600: #0891b2;
    --cyan-700: #0e7490;
    --cyan-800: #155e75;
    --cyan-900: #164e63;
    --cyan-950: #083344;
    --sky-50: #f0f9ff;
    --sky-100: #e0f2fe;
    --sky-200: #bae6fd;
    --sky-300: #7dd3fc;
    --sky-400: #38bdf8;
    --sky-500: #0ea5e9;
    --sky-600: #0284c7;
    --sky-700: #0369a1;
    --sky-800: #075985;
    --sky-900: #0c4a6e;
    --sky-950: #082f49;
    --blue-50: #eff6ff;
    --blue-100: #dbeafe;
    --blue-200: #bfdbfe;
    --blue-300: #93c5fd;
    --blue-400: #60a5fa;
    --blue-500: #3b82f6;
    --blue-600: #2563eb;
    --blue-700: #1d4ed8;
    --blue-800: #1e40af;
    --blue-900: #1e3a8a;
    --blue-950: #172554;
    --indigo-50: #eef2ff;
    --indigo-100: #e0e7ff;
    --indigo-200: #c7d2fe;
    --indigo-300: #a5b4fc;
    --indigo-400: #818cf8;
    --indigo-500: #6366f1;
    --indigo-600: #4f46e5;
    --indigo-700: #4338ca;
    --indigo-800: #3730a3;
    --indigo-900: #312e81;
    --indigo-950: #1e1b4b;
    --violet-50: #f5f3ff;
    --violet-100: #ede9fe;
    --violet-200: #ddd6fe;
    --violet-300: #c4b5fd;
    --violet-400: #a78bfa;
    --violet-500: #8b5cf6;
    --violet-600: #7c3aed;
    --violet-700: #6d28d9;
    --violet-800: #5b21b6;
    --violet-900: #4c1d95;
    --violet-950: #2e1065;
    --purple-50: #faf5ff;
    --purple-100: #f3e8ff;
    --purple-200: #e9d5ff;
    --purple-300: #d8b4fe;
    --purple-400: #c084fc;
    --purple-500: #a855f7;
    --purple-600: #9333ea;
    --purple-700: #7e22ce;
    --purple-800: #6b21a8;
    --purple-900: #581c87;
    --purple-950: #3b0764;
    --fuchsia-50: #fdf4ff;
    --fuchsia-100: #fae8ff;
    --fuchsia-200: #f5d0fe;
    --fuchsia-300: #f0abfc;
    --fuchsia-400: #e879f9;
    --fuchsia-500: #d946ef;
    --fuchsia-600: #c026d3;
    --fuchsia-700: #a21caf;
    --fuchsia-800: #86198f;
    --fuchsia-900: #701a75;
    --fuchsia-950: #4a044e;
    --pink-50: #fdf2f8;
    --pink-100: #fce7f3;
    --pink-200: #fbcfe8;
    --pink-300: #f9a8d4;
    --pink-400: #f472b6;
    --pink-500: #ec4899;
    --pink-600: #db2777;
    --pink-700: #be185d;
    --pink-800: #9d174d;
    --pink-900: #831843;
    --pink-950: #500724;
    --rose-50: #fff1f2;
    --rose-100: #ffe4e6;
    --rose-200: #fecdd3;
    --rose-300: #fda4af;
    --rose-400: #fb7185;
    --rose-500: #f43f5e;
    --rose-600: #e11d48;
    --rose-700: #be123c;
    --rose-800: #9f1239;
    --rose-900: #881337;
    --rose-950: #4c0519;
    --primary-600: #7f56d9;
    --base-white: #fff;
    --primary-800: #53389e;
    --primary-200: #e9d7fe;
    --primary-700: #6941c6;
    --primary-50: #f9f5ff;
    --darkgray: #9e9e9e;
    --darkslategray: #313139;
    --border: hsl(var(--border));
    --input: hsl(var(--input));
    --ring: hsl(var(--ring));
    --background: hsl(var(--background));
    --foreground: hsl(var(--foreground));
    --primary: hsl(var(--primary));
    --primary-foreground: hsl(var(--primary-foreground));
    --secondary: hsl(var(--secondary));
    --secondary-foreground: hsl(var(--secondary-foreground));
    --destructive: hsl(var(--destructive));
    --destructive-foreground: hsl(var(--destructive-foreground));
    --muted: hsl(var(--muted));
    --muted-foreground: hsl(var(--muted-foreground));
    --accent: hsl(var(--accent));
    --accent-foreground: hsl(var(--accent-foreground));
    --popover: hsl(var(--popover));
    --popover-foreground: hsl(var(--popover-foreground));
    --card: hsl(var(--card));
    --card-foreground: hsl(var(--card-foreground));
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 262.1 83.3% 57.8%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 262.1 83.3% 57.8%;
    --radius: 0.5rem;
}

.dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;
  }

* {
    border: 1px solid var(--border);
  }

body {
    background-color: var(--background);
  }

*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}

::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
.container{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
}
@media (min-width: 640px){

    .container{
        max-width: 640px;
    }
}
@media (min-width: 768px){

    .container{
        max-width: 768px;
    }
}
@media (min-width: 1024px){

    .container{
        max-width: 1024px;
    }
}
@media (min-width: 1280px){

    .container{
        max-width: 1280px;
    }
}
@media (min-width: 1536px){

    .container{
        max-width: 1536px;
    }
}
@tailwind utilities;

/* Additional styles */
@import "/app/theme.css";
@import "/app/utility-patterns.css";

.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.pointer-events-none{
    pointer-events: none;
}

.pointer-events-auto{
    pointer-events: auto;
}

.visible{
    visibility: visible;
}

.invisible{
    visibility: hidden;
}

.fixed{
    position: fixed;
}

.absolute{
    position: absolute;
}

.relative{
    position: relative;
}

.-inset-0{
    inset: -0px;
}

.-inset-0\.5{
    inset: -0.125rem;
}

.inset-0{
    inset: 0px;
}

.inset-x-0{
    left: 0px;
    right: 0px;
}

.-bottom-12{
    bottom: -3rem;
}

.-bottom-3{
    bottom: -0.75rem;
}

.-left-12{
    left: -3rem;
}

.-left-3{
    left: -0.75rem;
}

.-left-8{
    left: -2rem;
}

.-right-12{
    right: -3rem;
}

.-right-3{
    right: -0.75rem;
}

.-top-12{
    top: -3rem;
}

.-top-3{
    top: -0.75rem;
}

.bottom-0{
    bottom: 0px;
}

.left-0{
    left: 0px;
}

.left-1{
    left: 0.25rem;
}

.left-1\/2{
    left: 50%;
}

.left-2{
    left: 0.5rem;
}

.left-2\/3{
    left: 66.666667%;
}

.left-\[50\%\]{
    left: 50%;
}

.right-0{
    right: 0px;
}

.right-1{
    right: 0.25rem;
}

.right-2{
    right: 0.5rem;
}

.right-4{
    right: 1rem;
}

.top-0{
    top: 0px;
}

.top-1{
    top: 0.25rem;
}

.top-1\/2{
    top: 50%;
}

.top-2{
    top: 0.5rem;
}

.top-4{
    top: 1rem;
}

.top-\[50\%\]{
    top: 50%;
}

.-z-10{
    z-index: -10;
}

.z-0{
    z-index: 0;
}

.z-10{
    z-index: 10;
}

.z-20{
    z-index: 20;
}

.z-30{
    z-index: 30;
}

.z-50{
    z-index: 50;
}

.z-\[100\]{
    z-index: 100;
}

.order-1{
    order: 1;
}

.order-first{
    order: -9999;
}

.col-span-3{
    grid-column: span 3 / span 3;
}

.-m-1{
    margin: -0.25rem;
}

.-m-1\.5{
    margin: -0.375rem;
}

.m-1{
    margin: 0.25rem;
}

.m-1\.5{
    margin: 0.375rem;
}

.m-4{
    margin: 1rem;
}

.-mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}

.-mx-28{
    margin-left: -7rem;
    margin-right: -7rem;
}

.mx-0{
    margin-left: 0px;
    margin-right: 0px;
}

.mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

.my-0{
    margin-top: 0px;
    margin-bottom: 0px;
}

.my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-\[26px\]{
    margin-top: 26px;
    margin-bottom: 26px;
}

.my-\[30px\]{
    margin-top: 30px;
    margin-bottom: 30px;
}

.my-\[40px\]{
    margin-top: 40px;
    margin-bottom: 40px;
}

.my-auto{
    margin-top: auto;
    margin-bottom: auto;
}

.-mb-20{
    margin-bottom: -5rem;
}

.-ml-16{
    margin-left: -4rem;
}

.-ml-32{
    margin-left: -8rem;
}

.-ml-4{
    margin-left: -1rem;
}

.-mt-12{
    margin-top: -3rem;
}

.-mt-24{
    margin-top: -6rem;
}

.-mt-4{
    margin-top: -1rem;
}

.-mt-6{
    margin-top: -1.5rem;
}

.mb-1{
    margin-bottom: 0.25rem;
}

.mb-10{
    margin-bottom: 2.5rem;
}

.mb-2{
    margin-bottom: 0.5rem;
}

.mb-3{
    margin-bottom: 0.75rem;
}

.mb-4{
    margin-bottom: 1rem;
}

.mb-5{
    margin-bottom: 1.25rem;
}

.mb-6{
    margin-bottom: 1.5rem;
}

.mb-8{
    margin-bottom: 2rem;
}

.ml-1{
    margin-left: 0.25rem;
}

.ml-2{
    margin-left: 0.5rem;
}

.ml-4{
    margin-left: 1rem;
}

.ml-6{
    margin-left: 1.5rem;
}

.ml-auto{
    margin-left: auto;
}

.mr-2{
    margin-right: 0.5rem;
}

.mr-3{
    margin-right: 0.75rem;
}

.mr-4{
    margin-right: 1rem;
}

.mt-0{
    margin-top: 0px;
}

.mt-1{
    margin-top: 0.25rem;
}

.mt-10{
    margin-top: 2.5rem;
}

.mt-2{
    margin-top: 0.5rem;
}

.mt-24{
    margin-top: 6rem;
}

.mt-4{
    margin-top: 1rem;
}

.mt-6{
    margin-top: 1.5rem;
}

.mt-8{
    margin-top: 2rem;
}

.mt-\[21px\]{
    margin-top: 21px;
}

.mt-\[32px\]{
    margin-top: 32px;
}

.block{
    display: block;
}

.inline-block{
    display: inline-block;
}

.inline{
    display: inline;
}

.flex{
    display: flex;
}

.inline-flex{
    display: inline-flex;
}

.table{
    display: table;
}

.grid{
    display: grid;
}

.hidden{
    display: none;
}

.h-1\/4{
    height: 25%;
}

.h-10{
    height: 2.5rem;
}

.h-11{
    height: 2.75rem;
}

.h-12{
    height: 3rem;
}

.h-16{
    height: 4rem;
}

.h-2{
    height: 0.5rem;
}

.h-2\.5{
    height: 0.625rem;
}

.h-24{
    height: 6rem;
}

.h-3{
    height: 0.75rem;
}

.h-3\.5{
    height: 0.875rem;
}

.h-32{
    height: 8rem;
}

.h-4{
    height: 1rem;
}

.h-44{
    height: 11rem;
}

.h-48{
    height: 12rem;
}

.h-5{
    height: 1.25rem;
}

.h-6{
    height: 1.5rem;
}

.h-64{
    height: 16rem;
}

.h-7{
    height: 1.75rem;
}

.h-8{
    height: 2rem;
}

.h-80{
    height: 20rem;
}

.h-9{
    height: 2.25rem;
}

.h-\[1px\]{
    height: 1px;
}

.h-\[200\%\]{
    height: 200%;
}

.h-\[24px\]{
    height: 24px;
}

.h-\[300px\]{
    height: 300px;
}

.h-\[30rem\]{
    height: 30rem;
}

.h-\[450px\]{
    height: 450px;
}

.h-\[480px\]{
    height: 480px;
}

.h-\[500px\]{
    height: 500px;
}

.h-\[66px\]{
    height: 66px;
}

.h-\[var\(--radix-select-trigger-height\)\]{
    height: var(--radix-select-trigger-height);
}

.h-auto{
    height: auto;
}

.h-full{
    height: 100%;
}

.h-px{
    height: 1px;
}

.h-screen{
    height: 100vh;
}

.max-h-\[300px\]{
    max-height: 300px;
}

.max-h-screen{
    max-height: 100vh;
}

.min-h-\[20px\]{
    min-height: 20px;
}

.min-h-\[300px\]{
    min-height: 300px;
}

.min-h-\[400px\]{
    min-height: 400px;
}

.min-h-\[80px\]{
    min-height: 80px;
}

.min-h-screen{
    min-height: 100vh;
}

.\!w-auto{
    width: auto !important;
}

.w-1\/2{
    width: 50%;
}

.w-1\/3{
    width: 33.333333%;
}

.w-1\/4{
    width: 25%;
}

.w-10{
    width: 2.5rem;
}

.w-11{
    width: 2.75rem;
}

.w-12{
    width: 3rem;
}

.w-16{
    width: 4rem;
}

.w-2{
    width: 0.5rem;
}

.w-2\.5{
    width: 0.625rem;
}

.w-24{
    width: 6rem;
}

.w-3{
    width: 0.75rem;
}

.w-3\.5{
    width: 0.875rem;
}

.w-3\/4{
    width: 75%;
}

.w-32{
    width: 8rem;
}

.w-4{
    width: 1rem;
}

.w-44{
    width: 11rem;
}

.w-48{
    width: 12rem;
}

.w-5{
    width: 1.25rem;
}

.w-56{
    width: 14rem;
}

.w-6{
    width: 1.5rem;
}

.w-64{
    width: 16rem;
}

.w-7{
    width: 1.75rem;
}

.w-72{
    width: 18rem;
}

.w-8{
    width: 2rem;
}

.w-80{
    width: 20rem;
}

.w-9{
    width: 2.25rem;
}

.w-96{
    width: 24rem;
}

.w-\[130px\]{
    width: 130px;
}

.w-\[1px\]{
    width: 1px;
}

.w-\[200px\]{
    width: 200px;
}

.w-\[250px\]{
    width: 250px;
}

.w-\[260px\]{
    width: 260px;
}

.w-\[340px\]{
    width: 340px;
}

.w-\[380px\]{
    width: 380px;
}

.w-\[44px\]{
    width: 44px;
}

.w-\[465px\]{
    width: 465px;
}

.w-\[480px\]{
    width: 480px;
}

.w-\[48rem\]{
    width: 48rem;
}

.w-\[500px\]{
    width: 500px;
}

.w-\[90px\]{
    width: 90px;
}

.w-auto{
    width: auto;
}

.w-fit{
    width: -moz-fit-content;
    width: fit-content;
}

.w-full{
    width: 100%;
}

.min-w-0{
    min-width: 0px;
}

.min-w-\[12rem\]{
    min-width: 12rem;
}

.min-w-\[8rem\]{
    min-width: 8rem;
}

.min-w-\[var\(--radix-select-trigger-width\)\]{
    min-width: var(--radix-select-trigger-width);
}

.max-w-2xl{
    max-width: 42rem;
}

.max-w-3xl{
    max-width: 48rem;
}

.max-w-6xl{
    max-width: 72rem;
}

.max-w-7xl{
    max-width: 80rem;
}

.max-w-\[420px\]{
    max-width: 420px;
}

.max-w-lg{
    max-width: 32rem;
}

.max-w-md{
    max-width: 28rem;
}

.max-w-none{
    max-width: none;
}

.max-w-screen-xl{
    max-width: 1280px;
}

.max-w-sm{
    max-width: 24rem;
}

.max-w-xl{
    max-width: 36rem;
}

.max-w-xs{
    max-width: 20rem;
}

.flex-1{
    flex: 1 1 0%;
}

.shrink-0{
    flex-shrink: 0;
}

.grow{
    flex-grow: 1;
}

.grow-0{
    flex-grow: 0;
}

.basis-full{
    flex-basis: 100%;
}

.border-collapse{
    border-collapse: collapse;
}

.-translate-x-1\/2{
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-4{
    --tw-translate-x: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2{
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/4{
    --tw-translate-y: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0{
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-4{
    --tw-translate-x: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-50\%\]{
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2{
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\]{
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-\[14deg\]{
    --tw-rotate: -14deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-\[60deg\]{
    --tw-rotate: -60deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0{
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90{
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[60deg\]{
    --tw-rotate: 60deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse{

    50%{
        opacity: .5;
    }
}

.animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes spin{

    to{
        transform: rotate(360deg);
    }
}

.animate-spin{
    animation: spin 1s linear infinite;
}

.cursor-default{
    cursor: default;
}

.cursor-pointer{
    cursor: pointer;
}

.touch-none{
    touch-action: none;
}

.select-none{
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

.resize{
    resize: both;
}

.grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-row{
    flex-direction: row;
}

.flex-col{
    flex-direction: column;
}

.flex-col-reverse{
    flex-direction: column-reverse;
}

.flex-wrap{
    flex-wrap: wrap;
}

.place-items-center{
    place-items: center;
}

.items-start{
    align-items: flex-start;
}

.items-center{
    align-items: center;
}

.items-stretch{
    align-items: stretch;
}

.justify-start{
    justify-content: flex-start;
}

.justify-end{
    justify-content: flex-end;
}

.justify-center{
    justify-content: center;
}

.justify-between{
    justify-content: space-between;
}

.gap-1{
    gap: 0.25rem;
}

.gap-2{
    gap: 0.5rem;
}

.gap-3{
    gap: 0.75rem;
}

.gap-4{
    gap: 1rem;
}

.gap-6{
    gap: 1.5rem;
}

.gap-8{
    gap: 2rem;
}

.gap-x-6{
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
}

.gap-x-8{
    -moz-column-gap: 2rem;
         column-gap: 2rem;
}

.gap-y-16{
    row-gap: 4rem;
}

.gap-y-3{
    row-gap: 0.75rem;
}

.gap-y-3\.5{
    row-gap: 0.875rem;
}

.gap-y-6{
    row-gap: 1.5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1;
}

.divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-slate-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-divide-opacity));
}

.overflow-hidden{
    overflow: hidden;
}

.overflow-y-auto{
    overflow-y: auto;
}

.overflow-x-hidden{
    overflow-x: hidden;
}

.truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.whitespace-nowrap{
    white-space: nowrap;
}

.whitespace-pre-wrap{
    white-space: pre-wrap;
}

.break-words{
    overflow-wrap: break-word;
}

.rounded{
    border-radius: 0.25rem;
}

.rounded-2xl{
    border-radius: 1rem;
}

.rounded-3xl{
    border-radius: 1.5rem;
}

.rounded-\[2\.5rem\]{
    border-radius: 2.5rem;
}

.rounded-\[3rem\]{
    border-radius: 3rem;
}

.rounded-\[inherit\]{
    border-radius: inherit;
}

.rounded-full{
    border-radius: 9999px;
}

.rounded-lg{
    border-radius: var(--radius);
}

.rounded-md{
    border-radius: calc(var(--radius) - 2px);
}

.rounded-sm{
    border-radius: calc(var(--radius) - 4px);
}

.rounded-xl{
    border-radius: 20px;
}

.rounded-b-\[3rem\]{
    border-bottom-right-radius: 3rem;
    border-bottom-left-radius: 3rem;
}

.rounded-t-\[3rem\]{
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
}

.border{
    border-width: 1px;
}

.border-0{
    border-width: 0px;
}

.border-2{
    border-width: 2px;
}

.border-b{
    border-bottom-width: 1px;
}

.border-l{
    border-left-width: 1px;
}

.border-t{
    border-top-width: 1px;
}

.border-solid{
    border-style: solid;
}

.border-dashed{
    border-style: dashed;
}

.border-none{
    border-style: none;
}

.border-\[\#eaeaea\]{
    --tw-border-opacity: 1;
    border-color: rgb(234 234 234 / var(--tw-border-opacity));
}

.border-black\/\[0\.2\]{
    border-color: rgb(0 0 0 / 0.2);
}

.border-destructive{
    border-color: hsl(var(--destructive));
}

.border-destructive\/50{
    border-color: hsl(var(--destructive) / 0.5);
}

.border-input{
    border-color: hsl(var(--input));
}

.border-neutral-700{
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.border-neutral-800{
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

.border-primary{
    border-color: hsl(var(--primary));
}

.border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-slate-700{
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.border-slate-800{
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.border-transparent{
    border-color: transparent;
}

.border-zinc-800{
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity));
}

.border-l-transparent{
    border-left-color: transparent;
}

.border-t-transparent{
    border-top-color: transparent;
}

.bg-\[\#030712\]{
    --tw-bg-opacity: 1;
    background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.bg-\[\#0e0e0e\]{
    --tw-bg-opacity: 1;
    background-color: rgb(14 14 14 / var(--tw-bg-opacity));
}

.bg-accent{
    background-color: hsl(var(--accent));
}

.bg-background{
    background-color: hsl(var(--background));
}

.bg-background\/80{
    background-color: hsl(var(--background) / 0.8);
}

.bg-black{
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-600\/50{
    background-color: rgb(37 99 235 / 0.5);
}

.bg-border{
    background-color: hsl(var(--border));
}

.bg-card{
    background-color: hsl(var(--card));
}

.bg-destructive{
    background-color: hsl(var(--destructive));
}

.bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgb(208 213 221 / var(--tw-bg-opacity));
}

.bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgb(16 24 40 / var(--tw-bg-opacity));
}

.bg-green-500\/50{
    background-color: rgb(34 197 94 / 0.5);
}

.bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-muted{
    background-color: hsl(var(--muted));
}

.bg-neutral-950{
    --tw-bg-opacity: 1;
    background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

.bg-orange-500\/50{
    background-color: rgb(249 115 22 / 0.5);
}

.bg-pink-300\/50{
    background-color: rgb(249 168 212 / 0.5);
}

.bg-pink-600\/50{
    background-color: rgb(219 39 119 / 0.5);
}

.bg-popover{
    background-color: hsl(var(--popover));
}

.bg-primary{
    background-color: hsl(var(--primary));
}

.bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.bg-purple-600\/50{
    background-color: rgb(147 51 234 / 0.5);
}

.bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-red-600\/50{
    background-color: rgb(220 38 38 / 0.5);
}

.bg-secondary{
    background-color: hsl(var(--secondary));
}

.bg-slate-400{
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-slate-800{
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-800\/25{
    background-color: rgb(30 41 59 / 0.25);
}

.bg-slate-900{
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-slate-950{
    --tw-bg-opacity: 1;
    background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.bg-transparent{
    background-color: transparent;
}

.bg-white{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/\[0\.8\]{
    background-color: rgb(255 255 255 / 0.8);
}

.bg-yellow-600\/50{
    background-color: rgb(202 138 4 / 0.5);
}

.bg-zinc-800{
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.bg-zinc-950{
    --tw-bg-opacity: 1;
    background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.bg-opacity-25{
    --tw-bg-opacity: 0.25;
}

.bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.from-\[\#a2a0f6\]{
    --tw-gradient-from: #a2a0f6 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(162 160 246 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#c7d2fe\]{
    --tw-gradient-from: #c7d2fe var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue-600{
    --tw-gradient-from: #2563eb var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green-500{
    --tw-gradient-from: #22c55e var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green-600{
    --tw-gradient-from: #16a34a var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200{
    --tw-gradient-from: #e5e5e5 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500{
    --tw-gradient-from: #a855f7 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600{
    --tw-gradient-from: #9333ea var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red-600{
    --tw-gradient-from: #dc2626 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500{
    --tw-gradient-from: #0ea5e9 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/60{
    --tw-gradient-from: rgb(226 232 240 / 0.6) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/80{
    --tw-gradient-from: rgb(255 255 255 / 0.8) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow-600{
    --tw-gradient-from: #ca8a04 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-slate-200{
    --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-white{
    --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-\[\#8678f9\]{
    --tw-gradient-to: #8678f9 var(--tw-gradient-to-position);
}

.to-blue-700{
    --tw-gradient-to: #1d4ed8 var(--tw-gradient-to-position);
}

.to-green-500{
    --tw-gradient-to: #22c55e var(--tw-gradient-to-position);
}

.to-indigo-600{
    --tw-gradient-to: #4f46e5 var(--tw-gradient-to-position);
}

.to-neutral-600{
    --tw-gradient-to: #525252 var(--tw-gradient-to-position);
}

.to-purple-200{
    --tw-gradient-to: #e9d5ff var(--tw-gradient-to-position);
}

.to-purple-400{
    --tw-gradient-to: #c084fc var(--tw-gradient-to-position);
}

.to-purple-500{
    --tw-gradient-to: #a855f7 var(--tw-gradient-to-position);
}

.to-red-500{
    --tw-gradient-to: #ef4444 var(--tw-gradient-to-position);
}

.to-sky-500{
    --tw-gradient-to: #0ea5e9 var(--tw-gradient-to-position);
}

.to-slate-200\/60{
    --tw-gradient-to: rgb(226 232 240 / 0.6) var(--tw-gradient-to-position);
}

.to-white\/80{
    --tw-gradient-to: rgb(255 255 255 / 0.8) var(--tw-gradient-to-position);
}

.to-yellow-500{
    --tw-gradient-to: #eab308 var(--tw-gradient-to-position);
}

.bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text;
}

.fill-current{
    fill: currentColor;
}

.fill-purple-500{
    fill: #a855f7;
}

.fill-slate-300{
    fill: #cbd5e1;
}

.fill-slate-500{
    fill: #64748b;
}

.object-cover{
    -o-object-fit: cover;
       object-fit: cover;
}

.p-0{
    padding: 0px;
}

.p-0\.5{
    padding: 0.125rem;
}

.p-1{
    padding: 0.25rem;
}

.p-1\.5{
    padding: 0.375rem;
}

.p-2{
    padding: 0.5rem;
}

.p-24{
    padding: 6rem;
}

.p-3{
    padding: 0.75rem;
}

.p-3\.5{
    padding: 0.875rem;
}

.p-4{
    padding: 1rem;
}

.p-5{
    padding: 1.25rem;
}

.p-6{
    padding: 1.5rem;
}

.p-8{
    padding: 2rem;
}

.p-\[1px\]{
    padding: 1px;
}

.p-\[20px\]{
    padding: 20px;
}

.p-px{
    padding: 1px;
}

.px-0{
    padding-left: 0px;
    padding-right: 0px;
}

.px-0\.5{
    padding-left: 0.125rem;
    padding-right: 0.125rem;
}

.px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.px-16{
    padding-left: 4rem;
    padding-right: 4rem;
}

.px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.px-3\.5{
    padding-left: 0.875rem;
    padding-right: 0.875rem;
}

.px-4{
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.px-8{
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-0{
    padding-top: 0px;
    padding-bottom: 0px;
}

.py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.py-12{
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.py-24{
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.pb-0{
    padding-bottom: 0px;
}

.pb-0\.5{
    padding-bottom: 0.125rem;
}

.pb-1{
    padding-bottom: 0.25rem;
}

.pb-12{
    padding-bottom: 3rem;
}

.pb-16{
    padding-bottom: 4rem;
}

.pb-2{
    padding-bottom: 0.5rem;
}

.pb-3{
    padding-bottom: 0.75rem;
}

.pb-4{
    padding-bottom: 1rem;
}

.pb-5{
    padding-bottom: 1.25rem;
}

.pb-6{
    padding-bottom: 1.5rem;
}

.pb-8{
    padding-bottom: 2rem;
}

.pl-4{
    padding-left: 1rem;
}

.pl-8{
    padding-left: 2rem;
}

.pl-9{
    padding-left: 2.25rem;
}

.pr-2{
    padding-right: 0.5rem;
}

.pr-8{
    padding-right: 2rem;
}

.pt-0{
    padding-top: 0px;
}

.pt-1{
    padding-top: 0.25rem;
}

.pt-12{
    padding-top: 3rem;
}

.pt-16{
    padding-top: 4rem;
}

.pt-2{
    padding-top: 0.5rem;
}

.pt-20{
    padding-top: 5rem;
}

.pt-32{
    padding-top: 8rem;
}

.pt-4{
    padding-top: 1rem;
}

.pt-5{
    padding-top: 1.25rem;
}

.pt-6{
    padding-top: 1.5rem;
}

.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.font-inter{
    font-family: var(--font-inter), sans-serif;
}

.font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.font-sans{
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-2xl{
    font-size: 1.5rem;
    line-height: 1.415;
    letter-spacing: -0.017em;
}

.text-3xl{
    font-size: 1.875rem;
    line-height: 1.333;
    letter-spacing: -0.017em;
}

.text-4xl{
    font-size: 2.25rem;
    line-height: 1.277;
    letter-spacing: -0.017em;
}

.text-5xl{
    font-size: 2.75rem;
    line-height: 1.2;
    letter-spacing: -0.017em;
}

.text-\[0\.8rem\]{
    font-size: 0.8rem;
}

.text-\[12px\]{
    font-size: 12px;
}

.text-\[14px\]{
    font-size: 14px;
}

.text-\[24px\]{
    font-size: 24px;
}

.text-base{
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: -0.017em;
}

.text-lg{
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: -0.017em;
}

.text-sm{
    font-size: 0.875rem;
    line-height: 1.5715;
}

.text-xl{
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: -0.017em;
}

.text-xs{
    font-size: 0.75rem;
    line-height: 1.5;
}

.font-bold{
    font-weight: 700;
}

.font-light{
    font-weight: 300;
}

.font-medium{
    font-weight: 500;
}

.font-normal{
    font-weight: 400;
}

.font-semibold{
    font-weight: 600;
}

.lowercase{
    text-transform: lowercase;
}

.leading-6{
    line-height: 1.5rem;
}

.leading-7{
    line-height: 1.75rem;
}

.leading-8{
    line-height: 2rem;
}

.leading-\[24px\]{
    line-height: 24px;
}

.leading-none{
    line-height: 1;
}

.tracking-normal{
    letter-spacing: 0;
}

.tracking-tight{
    letter-spacing: -0.01em;
}

.tracking-widest{
    letter-spacing: 0.4em;
}

.text-\[\#666666\]{
    --tw-text-opacity: 1;
    color: rgb(102 102 102 / var(--tw-text-opacity));
}

.text-\[\#6B4CFF\]{
    --tw-text-opacity: 1;
    color: rgb(107 76 255 / var(--tw-text-opacity));
}

.text-accent-foreground{
    color: hsl(var(--accent-foreground));
}

.text-amber-300{
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity));
}

.text-black{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-card-foreground{
    color: hsl(var(--card-foreground));
}

.text-current{
    color: currentColor;
}

.text-destructive{
    color: hsl(var(--destructive));
}

.text-destructive-foreground{
    color: hsl(var(--destructive-foreground));
}

.text-foreground{
    color: hsl(var(--foreground));
}

.text-foreground\/50{
    color: hsl(var(--foreground) / 0.5);
}

.text-gray-300{
    --tw-text-opacity: 1;
    color: rgb(208 213 221 / var(--tw-text-opacity));
}

.text-gray-500{
    --tw-text-opacity: 1;
    color: rgb(102 112 133 / var(--tw-text-opacity));
}

.text-gray-600{
    --tw-text-opacity: 1;
    color: rgb(71 84 103 / var(--tw-text-opacity));
}

.text-gray-900{
    --tw-text-opacity: 1;
    color: rgb(16 24 40 / var(--tw-text-opacity));
}

.text-green-300{
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity));
}

.text-green-400{
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-indigo-600{
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-muted-foreground{
    color: hsl(var(--muted-foreground));
}

.text-neutral-500{
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-orange-400{
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-popover-foreground{
    color: hsl(var(--popover-foreground));
}

.text-primary{
    color: hsl(var(--primary));
}

.text-primary-foreground{
    color: hsl(var(--primary-foreground));
}

.text-purple-300{
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity));
}

.text-purple-500{
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-red-400{
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500{
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-secondary-foreground{
    color: hsl(var(--secondary-foreground));
}

.text-sky-300{
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity));
}

.text-slate-100{
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity));
}

.text-slate-200{
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}

.text-slate-300{
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-400{
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-50{
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity));
}

.text-slate-500{
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-600{
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-900{
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-teal-500{
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-transparent{
    color: transparent;
}

.text-white{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-400{
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity));
}

.text-zinc-700{
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-zinc-800{
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity));
}

.underline-offset-4{
    text-underline-offset: 4px;
}

.antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.opacity-0{
    opacity: 0;
}

.opacity-100{
    opacity: 1;
}

.opacity-20{
    opacity: 0.2;
}

.opacity-25{
    opacity: 0.25;
}

.opacity-30{
    opacity: 0.3;
}

.opacity-50{
    opacity: 0.5;
}

.opacity-60{
    opacity: 0.6;
}

.opacity-70{
    opacity: 0.7;
}

.opacity-75{
    opacity: 0.75;
}

.opacity-90{
    opacity: 0.9;
}

.mix-blend-overlay{
    mix-blend-mode: overlay;
}

.shadow{
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md{
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-purple-500\/25{
    --tw-shadow-color: rgb(168 85 247 / 0.25);
    --tw-shadow: var(--tw-shadow-colored);
}

.outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.outline{
    outline-style: solid;
}

.outline-slate-400{
    outline-color: #94a3b8;
}

.ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-gray-400\/10{
    --tw-ring-color: rgb(156 163 175 / 0.1);
}

.ring-offset-background{
    --tw-ring-offset-color: hsl(var(--background));
}

.blur{
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-2xl{
    --tw-blur: blur(40px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-3xl{
    --tw-blur: blur(64px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[120px\]{
    --tw-blur: blur(120px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[2px\]{
    --tw-blur: blur(2px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[40px\]{
    --tw-blur: blur(40px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[60px\]{
    --tw-blur: blur(60px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[80px\]{
    --tw-blur: blur(80px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-md{
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-sm{
    --tw-blur: blur(4px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale{
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-colors{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-transform{
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.delay-200{
    transition-delay: 200ms;
}

.delay-300{
    transition-delay: 300ms;
}

.duration-150{
    transition-duration: 150ms;
}

.duration-200{
    transition-duration: 200ms;
}

.duration-300{
    transition-duration: 300ms;
}

.duration-500{
    transition-duration: 500ms;
}

.duration-700{
    transition-duration: 700ms;
}

.\!ease-linear{
    transition-timing-function: linear !important;
}

.ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.will-change-transform{
    will-change: transform;
}

@keyframes enter{

    from{
        opacity: var(--tw-enter-opacity, 1);
        transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
    }
}

@keyframes exit{

    to{
        opacity: var(--tw-exit-opacity, 1);
        transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
    }
}

.animate-in{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}

.fade-in-0{
    --tw-enter-opacity: 0;
}

.fade-in-20{
    --tw-enter-opacity: 0.2;
}

.fade-in-80{
    --tw-enter-opacity: 0.8;
}

.zoom-in-95{
    --tw-enter-scale: .95;
}

.slide-in-from-top-5{
    --tw-enter-translate-y: -1.25rem;
}

.duration-150{
    animation-duration: 150ms;
}

.duration-200{
    animation-duration: 200ms;
}

.duration-300{
    animation-duration: 300ms;
}

.duration-500{
    animation-duration: 500ms;
}

.duration-700{
    animation-duration: 700ms;
}

.delay-200{
    animation-delay: 200ms;
}

.delay-300{
    animation-delay: 300ms;
}

.\!ease-linear{
    animation-timing-function: linear !important;
}

.ease-in-out{
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-out{
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.running{
    animation-play-state: running;
}

.\[background\:_repeating-linear-gradient\(90deg\2c transparent\2c _transparent_48px\2c _theme\(colors\.purple\.500\)_48px\2c _theme\(colors\.purple\.500\)_49px\)\]{
    background: repeating-linear-gradient(90deg,transparent, transparent 48px, #a855f7 48px, #a855f7 49px);
}

.\[background\:_repeating-linear-gradient\(90deg\2c transparent\2c _transparent_48px\2c _theme\(colors\.white\)_48px\2c _theme\(colors\.white\)_49px\)\]{
    background: repeating-linear-gradient(90deg,transparent, transparent 48px, #fff 48px, #fff 49px);
}

.\[background\:_repeating-linear-gradient\(transparent\2c _transparent_48px\2c _theme\(colors\.purple\.500\)_48px\2c _theme\(colors\.purple\.500\)_49px\)\]{
    background: repeating-linear-gradient(transparent, transparent 48px, #a855f7 48px, #a855f7 49px);
}

.\[background\:_repeating-linear-gradient\(transparent\2c _transparent_48px\2c _theme\(colors\.white\)_48px\2c _theme\(colors\.white\)_49px\)\]{
    background: repeating-linear-gradient(transparent, transparent 48px, #fff 48px, #fff 49px);
}

.\[background\:linear-gradient\(theme\(colors\.purple\.500\)\2c _theme\(colors\.purple\.500\)\)_padding-box\2c _linear-gradient\(theme\(colors\.purple\.500\)\2c _theme\(colors\.purple\.200\)_75\%\2c _theme\(colors\.transparent\)_100\%\)_border-box\]{
    background: linear-gradient(#a855f7, #a855f7) padding-box, linear-gradient(#a855f7, #e9d5ff 75%, transparent 100%) border-box;
}

.\[background\:linear-gradient\(theme\(colors\.slate\.900\)\2c _theme\(colors\.slate\.900\)\)_padding-box\2c _conic-gradient\(theme\(colors\.slate\.400\)\2c _theme\(colors\.slate\.700\)_25\%\2c _theme\(colors\.slate\.700\)_75\%\2c _theme\(colors\.slate\.400\)_100\%\)_border-box\]{
    background: linear-gradient(#0f172a, #0f172a) padding-box, conic-gradient(#94a3b8, #334155 25%, #334155 75%, #94a3b8 100%) border-box;
}

.\[mask-image\:_linear-gradient\(0deg\2c transparent\2c theme\(colors\.white\)_40\%\2c theme\(colors\.white\)\)\]{
    -webkit-mask-image: linear-gradient(0deg,transparent,#fff 40%,#fff);
            mask-image: linear-gradient(0deg,transparent,#fff 40%,#fff);
}

.\[mask-image\:_radial-gradient\(black\2c _transparent_60\%\)\]{
    -webkit-mask-image: radial-gradient(black, transparent 60%);
            mask-image: radial-gradient(black, transparent 60%);
}

.\[mask-image\:linear-gradient\(white\2c transparent\)\]{
    -webkit-mask-image: linear-gradient(white,transparent);
            mask-image: linear-gradient(white,transparent);
}

.\[mask-repeat\:no-repeat\]{
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
}

.\[mask-size\:40px\]{
    -webkit-mask-size: 40px;
            mask-size: 40px;
}

@import "/app/css/additional-styles/utility-patterns.css";
@import "/app/css/additional-styles/theme.css";

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */

.without-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-offset-width: 0px;
}

/* #pdf-controls {
  display: none;
} */

#pdf-download {
  display: none;
}

#pdf-toggle-pagination {
  display: none;
}

#pdf-zoom-reset {
  display: none;
}

#header-bar {
  display: none;
}

.show-on-print {
  display: none;
}

#modal-body {
  background-color: #0b0b0f;
}

#modal {
  background-color: #0b0b0f;
}

.mantine-Modal-header mantine-19pz3dh {
  background-color: #0b0b0f;
}

#mantine-Modal-header mantine-19pz3dh {
  background-color: #0b0b0f;
}

.mantine-Modal-header {
  background-color: #0b0b0f;
}

body {
  background-color: #0b0b0f;
}
@media print {
  /* Add styles to hide certain elements */
  .hide-on-print {
    display: none;
  }

  .pdf-viewer {
    display: none;
  }

  .show-on-print {
    display: block; /* or any other appropriate value for your elements */
  }

  /* Add styles to adjust the layout or font size for printing */
  .print-adjusted {
    font-size: 14px;
    margin: 10px;
  }
}
.file\:border-0::file-selector-button{
    border-width: 0px;
}
.file\:bg-transparent::file-selector-button{
    background-color: transparent;
}
.file\:text-sm::file-selector-button{
    font-size: 0.875rem;
    line-height: 1.5715;
}
.placeholder\:select-none::-moz-placeholder{
    -moz-user-select: none;
         user-select: none;
}
.placeholder\:select-none::placeholder{
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.placeholder\:text-muted-foreground::-moz-placeholder{
    color: hsl(var(--muted-foreground));
}
.placeholder\:text-muted-foreground::placeholder{
    color: hsl(var(--muted-foreground));
}
.placeholder\:text-neutral-500::-moz-placeholder{
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity));
}
.placeholder\:text-neutral-500::placeholder{
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity));
}
.placeholder\:text-neutral-700::-moz-placeholder{
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity));
}
.placeholder\:text-neutral-700::placeholder{
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity));
}
.before\:pointer-events-none::before{
    content: var(--tw-content);
    pointer-events: none;
}
.before\:absolute::before{
    content: var(--tw-content);
    position: absolute;
}
.before\:inset-0::before{
    content: var(--tw-content);
    inset: 0px;
}
.before\:-left-48::before{
    content: var(--tw-content);
    left: -12rem;
}
.before\:-top-48::before{
    content: var(--tw-content);
    top: -12rem;
}
.before\:-z-20::before{
    content: var(--tw-content);
    z-index: -20;
}
.before\:z-10::before{
    content: var(--tw-content);
    z-index: 10;
}
.before\:z-20::before{
    content: var(--tw-content);
    z-index: 20;
}
.before\:z-30::before{
    content: var(--tw-content);
    z-index: 30;
}
.before\:h-5::before{
    content: var(--tw-content);
    height: 1.25rem;
}
.before\:h-96::before{
    content: var(--tw-content);
    height: 24rem;
}
.before\:w-32::before{
    content: var(--tw-content);
    width: 8rem;
}
.before\:w-5::before{
    content: var(--tw-content);
    width: 1.25rem;
}
.before\:w-96::before{
    content: var(--tw-content);
    width: 24rem;
}
.before\:-translate-x-full::before{
    content: var(--tw-content);
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.before\:translate-x-\[var\(--mouse-x\)\]::before{
    content: var(--tw-content);
    --tw-translate-x: var(--mouse-x);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.before\:translate-y-\[var\(--mouse-y\)\]::before{
    content: var(--tw-content);
    --tw-translate-y: var(--mouse-y);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.before\:rounded-2xl::before{
    content: var(--tw-content);
    border-radius: 1rem;
}
.before\:rounded-full::before{
    content: var(--tw-content);
    border-radius: 9999px;
}
.before\:bg-purple-500::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.before\:bg-slate-800\/30::before{
    content: var(--tw-content);
    background-color: rgb(30 41 59 / 0.3);
}
.before\:bg-slate-800\/50::before{
    content: var(--tw-content);
    background-color: rgb(30 41 59 / 0.5);
}
.before\:bg-white::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.before\:bg-gradient-to-b::before{
    content: var(--tw-content);
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.before\:bg-gradient-to-l::before{
    content: var(--tw-content);
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.before\:from-slate-400\/20::before{
    content: var(--tw-content);
    --tw-gradient-from: rgb(148 163 184 / 0.2) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.before\:from-transparent::before{
    content: var(--tw-content);
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.before\:to-slate-900::before{
    content: var(--tw-content);
    --tw-gradient-to: #0f172a var(--tw-gradient-to-position);
}
.before\:to-transparent::before{
    content: var(--tw-content);
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
}
.before\:to-20\%::before{
    content: var(--tw-content);
    --tw-gradient-to-position: 20%;
}
.before\:opacity-0::before{
    content: var(--tw-content);
    opacity: 0;
}
.before\:shadow-sm::before{
    content: var(--tw-content);
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.before\:blur-\[100px\]::before{
    content: var(--tw-content);
    --tw-blur: blur(100px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.before\:blur-md::before{
    content: var(--tw-content);
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.before\:transition-opacity::before{
    content: var(--tw-content);
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.before\:transition-transform::before{
    content: var(--tw-content);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.before\:duration-150::before{
    content: var(--tw-content);
    transition-duration: 150ms;
}
.before\:duration-500::before{
    content: var(--tw-content);
    transition-duration: 500ms;
}
.before\:duration-150::before{
    content: var(--tw-content);
    animation-duration: 150ms;
}
.before\:duration-500::before{
    content: var(--tw-content);
    animation-duration: 500ms;
}
.after\:pointer-events-none::after{
    content: var(--tw-content);
    pointer-events: none;
}
.after\:absolute::after{
    content: var(--tw-content);
    position: absolute;
}
.after\:inset-0::after{
    content: var(--tw-content);
    inset: 0px;
}
.after\:left-auto::after{
    content: var(--tw-content);
    left: auto;
}
.after\:-z-20::after{
    content: var(--tw-content);
    z-index: -20;
}
.after\:z-10::after{
    content: var(--tw-content);
    z-index: 10;
}
.after\:z-20::after{
    content: var(--tw-content);
    z-index: 20;
}
.after\:m-px::after{
    content: var(--tw-content);
    margin: 1px;
}
.after\:w-32::after{
    content: var(--tw-content);
    width: 8rem;
}
.after\:translate-x-full::after{
    content: var(--tw-content);
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.after\:rounded-\[inherit\]::after{
    content: var(--tw-content);
    border-radius: inherit;
}
.after\:rounded-full::after{
    content: var(--tw-content);
    border-radius: 9999px;
}
.after\:bg-slate-900::after{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
.after\:bg-gradient-to-r::after{
    content: var(--tw-content);
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.after\:from-transparent::after{
    content: var(--tw-content);
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.after\:to-slate-900::after{
    content: var(--tw-content);
    --tw-gradient-to: #0f172a var(--tw-gradient-to-position);
}
.after\:to-20\%::after{
    content: var(--tw-content);
    --tw-gradient-to-position: 20%;
}
.after\:opacity-0::after{
    content: var(--tw-content);
    opacity: 0;
}
.after\:transition-opacity::after{
    content: var(--tw-content);
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.after\:duration-500::after{
    transition-duration: 500ms;
    content: var(--tw-content);
    animation-duration: 500ms;
}
.after\:\[background\:_radial-gradient\(250px_circle_at_var\(--mouse-x\)_var\(--mouse-y\)\2c theme\(colors\.slate\.400\)\2c transparent\)\]::after{
    content: var(--tw-content);
    background: radial-gradient(250px circle at var(--mouse-x) var(--mouse-y),#94a3b8,transparent);
}
.first-of-type\:pt-0:first-of-type{
    padding-top: 0px;
}
.last-of-type\:pb-0:last-of-type{
    padding-bottom: 0px;
}
.focus-within\:relative:focus-within{
    position: relative;
}
.focus-within\:z-20:focus-within{
    z-index: 20;
}
.hover\:border-border:hover{
    border-color: hsl(var(--border));
}
.hover\:bg-accent:hover{
    background-color: hsl(var(--accent));
}
.hover\:bg-destructive\/80:hover{
    background-color: hsl(var(--destructive) / 0.8);
}
.hover\:bg-destructive\/90:hover{
    background-color: hsl(var(--destructive) / 0.9);
}
.hover\:bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(208 213 221 / var(--tw-bg-opacity));
}
.hover\:bg-indigo-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.hover\:bg-primary:hover{
    background-color: hsl(var(--primary));
}
.hover\:bg-primary\/80:hover{
    background-color: hsl(var(--primary) / 0.8);
}
.hover\:bg-purple-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}
.hover\:bg-purple-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}
.hover\:bg-purple-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}
.hover\:bg-purple-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}
.hover\:bg-secondary:hover{
    background-color: hsl(var(--secondary));
}
.hover\:bg-secondary\/80:hover{
    background-color: hsl(var(--secondary) / 0.8);
}
.hover\:bg-slate-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
.hover\:bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.hover\:bg-opacity-30:hover{
    --tw-bg-opacity: 0.3;
}
.hover\:text-accent-foreground:hover{
    color: hsl(var(--accent-foreground));
}
.hover\:text-foreground:hover{
    color: hsl(var(--foreground));
}
.hover\:text-primary:hover{
    color: hsl(var(--primary));
}
.hover\:text-primary-foreground:hover{
    color: hsl(var(--primary-foreground));
}
.hover\:text-purple-400:hover{
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
}
.hover\:text-slate-200:hover{
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}
.hover\:text-white:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hover\:underline:hover{
    text-decoration-line: underline;
}
.hover\:opacity-100:hover{
    opacity: 1;
}
.hover\:opacity-60:hover{
    opacity: 0.6;
}
.before\:hover\:opacity-20:hover::before{
    content: var(--tw-content);
    opacity: 0.2;
}
.focus\:border-\[\#8678F9\]:focus{
    --tw-border-opacity: 1;
    border-color: rgb(134 120 249 / var(--tw-border-opacity));
}
.focus\:bg-accent:focus{
    background-color: hsl(var(--accent));
}
.focus\:bg-primary:focus{
    background-color: hsl(var(--primary));
}
.focus\:text-accent-foreground:focus{
    color: hsl(var(--accent-foreground));
}
.focus\:text-primary-foreground:focus{
    color: hsl(var(--primary-foreground));
}
.focus\:opacity-100:focus{
    opacity: 1;
}
.focus\:outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-ring:focus{
    --tw-ring-color: hsl(var(--ring));
}
.focus\:ring-teal-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity));
}
.focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px;
}
.focus-visible\:outline-none:focus-visible{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus-visible\:outline:focus-visible{
    outline-style: solid;
}
.focus-visible\:outline-2:focus-visible{
    outline-width: 2px;
}
.focus-visible\:outline-offset-2:focus-visible{
    outline-offset: 2px;
}
.focus-visible\:outline-indigo-600:focus-visible{
    outline-color: #4f46e5;
}
.focus-visible\:ring-2:focus-visible{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-ring:focus-visible{
    --tw-ring-color: hsl(var(--ring));
}
.focus-visible\:ring-offset-2:focus-visible{
    --tw-ring-offset-width: 2px;
}
.focus-visible\:ring-offset-background:focus-visible{
    --tw-ring-offset-color: hsl(var(--background));
}
.disabled\:pointer-events-none:disabled{
    pointer-events: none;
}
.disabled\:cursor-not-allowed:disabled{
    cursor: not-allowed;
}
.disabled\:opacity-50:disabled{
    opacity: 0.5;
}
.group:hover .group-hover\:translate-x-0{
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:translate-x-0\.5{
    --tw-translate-x: 0.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:fill-purple-500{
    fill: #a855f7;
}
.group\/card:hover .group-hover\/card\:opacity-100{
    opacity: 1;
}
.group\/card:hover .group-hover\/card\:opacity-50{
    opacity: 0.5;
}
.group\/slide:hover .group-hover\/slide\:opacity-100{
    opacity: 1;
}
.group:hover .group-hover\:opacity-100{
    opacity: 1;
}
.group:hover .after\:group-hover\:opacity-100::after{
    content: var(--tw-content);
    opacity: 1;
}
.group.destructive .group-\[\.destructive\]\:border-muted\/40{
    border-color: hsl(var(--muted) / 0.4);
}
.group.toaster .group-\[\.toaster\]\:border-border{
    border-color: hsl(var(--border));
}
.group\/slide.swiper-slide-active .group-\[\.swiper-slide-active\]\/slide\:bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.group.toast .group-\[\.toast\]\:bg-muted{
    background-color: hsl(var(--muted));
}
.group.toast .group-\[\.toast\]\:bg-primary{
    background-color: hsl(var(--primary));
}
.group.toaster .group-\[\.toaster\]\:bg-background{
    background-color: hsl(var(--background));
}
.group.destructive .group-\[\.destructive\]\:text-red-300{
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity));
}
.group.toast .group-\[\.toast\]\:text-muted-foreground{
    color: hsl(var(--muted-foreground));
}
.group.toast .group-\[\.toast\]\:text-primary-foreground{
    color: hsl(var(--primary-foreground));
}
.group.toaster .group-\[\.toaster\]\:text-foreground{
    color: hsl(var(--foreground));
}
.group\/slide.swiper-slide-active .group-\[\.swiper-slide-active\]\/slide\:opacity-100{
    opacity: 1;
}
.group.toaster .group-\[\.toaster\]\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group.destructive .group-\[\.destructive\]\:hover\:border-destructive\/30:hover{
    border-color: hsl(var(--destructive) / 0.3);
}
.group.destructive .group-\[\.destructive\]\:hover\:bg-destructive:hover{
    background-color: hsl(var(--destructive));
}
.group.destructive .group-\[\.destructive\]\:hover\:text-destructive-foreground:hover{
    color: hsl(var(--destructive-foreground));
}
.group.destructive .group-\[\.destructive\]\:hover\:text-red-50:hover{
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity));
}
.group.destructive .group-\[\.destructive\]\:focus\:ring-destructive:focus{
    --tw-ring-color: hsl(var(--destructive));
}
.group.destructive .group-\[\.destructive\]\:focus\:ring-red-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}
.group.destructive .group-\[\.destructive\]\:focus\:ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626;
}
.peer:checked ~ .peer-checked\:bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.peer:checked ~ .peer-checked\:before\:translate-x-full::before{
    content: var(--tw-content);
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:focus-visible ~ .peer-focus-visible\:outline{
    outline-style: solid;
}
.peer:focus-visible ~ .peer-focus-visible\:outline-offset-2{
    outline-offset: 2px;
}
.peer:focus-visible ~ .peer-focus-visible\:outline-gray-400{
    outline-color: #9ca3af;
}
.peer:checked:focus-visible ~ .peer-checked\:peer-focus-visible\:outline-purple-500{
    outline-color: #a855f7;
}
.peer:disabled ~ .peer-disabled\:cursor-not-allowed{
    cursor: not-allowed;
}
.peer:disabled ~ .peer-disabled\:opacity-70{
    opacity: 0.7;
}
.aria-selected\:bg-accent[aria-selected="true"]{
    background-color: hsl(var(--accent));
}
.aria-selected\:text-accent-foreground[aria-selected="true"]{
    color: hsl(var(--accent-foreground));
}
.aria-selected\:opacity-100[aria-selected="true"]{
    opacity: 1;
}
.data-\[disabled\]\:pointer-events-none[data-disabled]{
    pointer-events: none;
}
.data-\[state\=active\]\:flex[data-state=active]{
    display: flex;
}
.data-\[side\=bottom\]\:translate-y-1[data-side=bottom]{
    --tw-translate-y: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=left\]\:-translate-x-1[data-side=left]{
    --tw-translate-x: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=right\]\:translate-x-1[data-side=right]{
    --tw-translate-x: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=top\]\:-translate-y-1[data-side=top]{
    --tw-translate-y: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=checked\]\:translate-x-5[data-state=checked]{
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=unchecked\]\:translate-x-0[data-state=unchecked]{
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[swipe\=cancel\]\:translate-x-0[data-swipe=cancel]{
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[swipe\=end\]\:translate-x-\[var\(--radix-toast-swipe-end-x\)\][data-swipe=end]{
    --tw-translate-x: var(--radix-toast-swipe-end-x);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[swipe\=move\]\:translate-x-\[var\(--radix-toast-swipe-move-x\)\][data-swipe=move]{
    --tw-translate-x: var(--radix-toast-swipe-move-x);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=active\]\:bg-background[data-state=active]{
    background-color: hsl(var(--background));
}
.data-\[state\=checked\]\:bg-primary[data-state=checked]{
    background-color: hsl(var(--primary));
}
.data-\[state\=open\]\:bg-accent[data-state=open]{
    background-color: hsl(var(--accent));
}
.data-\[state\=unchecked\]\:bg-input[data-state=unchecked]{
    background-color: hsl(var(--input));
}
.data-\[state\=active\]\:text-foreground[data-state=active]{
    color: hsl(var(--foreground));
}
.data-\[state\=checked\]\:text-primary-foreground[data-state=checked]{
    color: hsl(var(--primary-foreground));
}
.data-\[state\=open\]\:text-accent-foreground[data-state=open]{
    color: hsl(var(--accent-foreground));
}
.data-\[state\=open\]\:text-muted-foreground[data-state=open]{
    color: hsl(var(--muted-foreground));
}
.data-\[disabled\]\:opacity-50[data-disabled]{
    opacity: 0.5;
}
.data-\[state\=active\]\:shadow-sm[data-state=active]{
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.data-\[swipe\=move\]\:transition-none[data-swipe=move]{
    transition-property: none;
}
.data-\[state\=open\]\:animate-in[data-state=open]{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[state\=closed\]\:animate-out[data-state=closed]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[swipe\=end\]\:animate-out[data-swipe=end]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[state\=closed\]\:fade-out-0[data-state=closed]{
    --tw-exit-opacity: 0;
}
.data-\[state\=closed\]\:fade-out-80[data-state=closed]{
    --tw-exit-opacity: 0.8;
}
.data-\[state\=open\]\:fade-in-0[data-state=open]{
    --tw-enter-opacity: 0;
}
.data-\[state\=closed\]\:zoom-out-95[data-state=closed]{
    --tw-exit-scale: .95;
}
.data-\[state\=open\]\:zoom-in-95[data-state=open]{
    --tw-enter-scale: .95;
}
.data-\[side\=bottom\]\:slide-in-from-top-2[data-side=bottom]{
    --tw-enter-translate-y: -0.5rem;
}
.data-\[side\=left\]\:slide-in-from-right-2[data-side=left]{
    --tw-enter-translate-x: 0.5rem;
}
.data-\[side\=right\]\:slide-in-from-left-2[data-side=right]{
    --tw-enter-translate-x: -0.5rem;
}
.data-\[side\=top\]\:slide-in-from-bottom-2[data-side=top]{
    --tw-enter-translate-y: 0.5rem;
}
.data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state=closed]{
    --tw-exit-translate-x: -50%;
}
.data-\[state\=closed\]\:slide-out-to-right-full[data-state=closed]{
    --tw-exit-translate-x: 100%;
}
.data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state=closed]{
    --tw-exit-translate-y: -48%;
}
.data-\[state\=open\]\:slide-in-from-left-1\/2[data-state=open]{
    --tw-enter-translate-x: -50%;
}
.data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state=open]{
    --tw-enter-translate-y: -48%;
}
.data-\[state\=open\]\:slide-in-from-top-full[data-state=open]{
    --tw-enter-translate-y: -100%;
}
.dark\:block:is(.dark *){
    display: block;
}
.dark\:hidden:is(.dark *){
    display: none;
}
.dark\:border-destructive:is(.dark *){
    border-color: hsl(var(--destructive));
}
.dark\:border-white\/\[0\.2\]:is(.dark *){
    border-color: rgb(255 255 255 / 0.2);
}
.dark\:bg-black\/\[0\.8\]:is(.dark *){
    background-color: rgb(0 0 0 / 0.8);
}
.dark\:bg-blue-300:is(.dark *){
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}
.dark\:bg-green-300:is(.dark *){
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}
.dark\:bg-orange-300:is(.dark *){
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}
.dark\:bg-pink-300:is(.dark *){
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}
.dark\:bg-purple-300:is(.dark *){
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}
.dark\:bg-red-300:is(.dark *){
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}
.dark\:bg-yellow-300:is(.dark *){
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}
.dark\:from-green-300:is(.dark *){
    --tw-gradient-from: #86efac var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.dark\:from-purple-400:is(.dark *){
    --tw-gradient-from: #c084fc var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.dark\:from-red-300:is(.dark *){
    --tw-gradient-from: #fca5a5 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.dark\:from-sky-500:is(.dark *){
    --tw-gradient-from: #0ea5e9 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.dark\:from-yellow-300:is(.dark *){
    --tw-gradient-from: #fde047 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.dark\:to-green-100:is(.dark *){
    --tw-gradient-to: #dcfce7 var(--tw-gradient-to-position);
}
.dark\:to-purple-100:is(.dark *){
    --tw-gradient-to: #f3e8ff var(--tw-gradient-to-position);
}
.dark\:to-red-100:is(.dark *){
    --tw-gradient-to: #fee2e2 var(--tw-gradient-to-position);
}
.dark\:to-sky-200:is(.dark *){
    --tw-gradient-to: #bae6fd var(--tw-gradient-to-position);
}
.dark\:to-yellow-100:is(.dark *){
    --tw-gradient-to: #fef9c3 var(--tw-gradient-to-position);
}
.dark\:text-white:is(.dark *){
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media not all and (min-width: 768px){

    .max-md\:mx-auto{
        margin-left: auto;
        margin-right: auto;
    }

    .max-md\:hidden{
        display: none;
    }

    .max-md\:text-center{
        text-align: center;
    }
}
@media (min-width: 640px){

    .sm\:visible{
        visibility: visible;
    }

    .sm\:bottom-0{
        bottom: 0px;
    }

    .sm\:right-0{
        right: 0px;
    }

    .sm\:top-auto{
        top: auto;
    }

    .sm\:col-span-12{
        grid-column: span 12 / span 12;
    }

    .sm\:col-span-2{
        grid-column: span 2 / span 2;
    }

    .sm\:col-span-6{
        grid-column: span 6 / span 6;
    }

    .sm\:mb-0{
        margin-bottom: 0px;
    }

    .sm\:block{
        display: block;
    }

    .sm\:inline-flex{
        display: inline-flex;
    }

    .sm\:hidden{
        display: none;
    }

    .sm\:w-\[57rem\]{
        width: 57rem;
    }

    .sm\:max-w-\[425px\]{
        max-width: 425px;
    }

    .sm\:max-w-\[625px\]{
        max-width: 625px;
    }

    .sm\:max-w-none{
        max-width: none;
    }

    .sm\:grid-cols-12{
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .sm\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .sm\:flex-row{
        flex-direction: row;
    }

    .sm\:flex-col{
        flex-direction: column;
    }

    .sm\:justify-end{
        justify-content: flex-end;
    }

    .sm\:justify-center{
        justify-content: center;
    }

    .sm\:gap-y-20{
        row-gap: 5rem;
    }

    .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }

    .sm\:rounded-lg{
        border-radius: var(--radius);
    }

    .sm\:px-6{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .sm\:py-32{
        padding-top: 8rem;
        padding-bottom: 8rem;
    }

    .sm\:pb-12{
        padding-bottom: 3rem;
    }

    .sm\:text-left{
        text-align: left;
    }

    .sm\:text-2xl{
        font-size: 1.5rem;
        line-height: 1.415;
        letter-spacing: -0.017em;
    }

    .sm\:text-3xl{
        font-size: 1.875rem;
        line-height: 1.333;
        letter-spacing: -0.017em;
    }

    .sm\:text-4xl{
        font-size: 2.25rem;
        line-height: 1.277;
        letter-spacing: -0.017em;
    }

    .sm\:text-5xl{
        font-size: 2.75rem;
        line-height: 1.2;
        letter-spacing: -0.017em;
    }

    .sm\:text-6xl{
        font-size: 3.5rem;
        line-height: 1;
        letter-spacing: -0.017em;
    }

    .data-\[state\=open\]\:sm\:slide-in-from-bottom-full[data-state=open]{
        --tw-enter-translate-y: 100%;
    }
}
@media (min-width: 768px){

    .md\:visible{
        visibility: visible;
    }

    .md\:relative{
        position: relative;
    }

    .md\:left-0{
        left: 0px;
    }

    .md\:order-1{
        order: 1;
    }

    .md\:order-none{
        order: 0;
    }

    .md\:col-span-12{
        grid-column: span 12 / span 12;
    }

    .md\:col-span-3{
        grid-column: span 3 / span 3;
    }

    .md\:col-span-5{
        grid-column: span 5 / span 5;
    }

    .md\:col-span-7{
        grid-column: span 7 / span 7;
    }

    .md\:-ml-4{
        margin-left: -1rem;
    }

    .md\:block{
        display: block;
    }

    .md\:flex{
        display: flex;
    }

    .md\:hidden{
        display: none;
    }

    .md\:h-20{
        height: 5rem;
    }

    .md\:h-auto{
        height: auto;
    }

    .md\:min-h-\[30px\]{
        min-height: 30px;
    }

    .md\:min-h-\[700px\]{
        min-height: 700px;
    }

    .md\:w-5\/12{
        width: 41.666667%;
    }

    .md\:w-7\/12{
        width: 58.333333%;
    }

    .md\:w-\[100px\]{
        width: 100px;
    }

    .md\:w-full{
        width: 100%;
    }

    .md\:max-w-\[420px\]{
        max-width: 420px;
    }

    .md\:max-w-\[480px\]{
        max-width: 480px;
    }

    .md\:max-w-full{
        max-width: 100%;
    }

    .md\:max-w-none{
        max-width: none;
    }

    .md\:flex-1{
        flex: 1 1 0%;
    }

    .md\:translate-x-0{
        --tw-translate-x: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    .md\:grid-cols-12{
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .md\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .md\:grid-cols-3{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .md\:grid-cols-4{
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .md\:flex-row{
        flex-direction: row;
    }

    .md\:items-center{
        align-items: center;
    }

    .md\:justify-between{
        justify-content: space-between;
    }

    .md\:gap-12{
        gap: 3rem;
    }

    .md\:space-x-8 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(2rem * var(--tw-space-x-reverse));
        margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .md\:space-y-0 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }

    .md\:space-y-8 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0;
        margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(2rem * var(--tw-space-y-reverse));
    }

    .md\:border-b{
        border-bottom-width: 1px;
    }

    .md\:p-8{
        padding: 2rem;
    }

    .md\:px-20{
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .md\:py-12{
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .md\:py-16{
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .md\:py-20{
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .md\:pb-12{
        padding-bottom: 3rem;
    }

    .md\:pb-20{
        padding-bottom: 5rem;
    }

    .md\:pb-32{
        padding-bottom: 8rem;
    }

    .md\:pb-8{
        padding-bottom: 2rem;
    }

    .md\:pr-0{
        padding-right: 0px;
    }

    .md\:pt-20{
        padding-top: 5rem;
    }

    .md\:pt-32{
        padding-top: 8rem;
    }

    .md\:pt-52{
        padding-top: 13rem;
    }

    .md\:text-3xl{
        font-size: 1.875rem;
        line-height: 1.333;
        letter-spacing: -0.017em;
    }

    .md\:text-7xl{
        font-size: 4.5rem;
        line-height: 1;
        letter-spacing: -0.017em;
    }

    @media not all and (min-width: 1024px){

        .md\:max-lg\:hidden{
            display: none;
        }
    }
}
@media (min-width: 1024px){

    .lg\:order-none{
        order: 0;
    }

    .lg\:col-span-2{
        grid-column: span 2 / span 2;
    }

    .lg\:col-span-4{
        grid-column: span 4 / span 4;
    }

    .lg\:mx-0{
        margin-left: 0px;
        margin-right: 0px;
    }

    .lg\:-ml-0{
        margin-left: -0px;
    }

    .lg\:block{
        display: block;
    }

    .lg\:flex{
        display: flex;
    }

    .lg\:hidden{
        display: none;
    }

    .lg\:min-h-\[40px\]{
        min-height: 40px;
    }

    .lg\:min-h-\[580px\]{
        min-height: 580px;
    }

    .lg\:min-h-\[700px\]{
        min-height: 700px;
    }

    .lg\:w-1\/2{
        width: 50%;
    }

    .lg\:w-\[300px\]{
        width: 300px;
    }

    .lg\:w-\[650px\]{
        width: 650px;
    }

    .lg\:max-w-lg{
        max-width: 32rem;
    }

    .lg\:max-w-none{
        max-width: none;
    }

    .lg\:flex-1{
        flex: 1 1 0%;
    }

    .lg\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lg\:grid-cols-4{
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .lg\:grid-cols-5{
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .lg\:grid-rows-1{
        grid-template-rows: repeat(1, minmax(0, 1fr));
    }

    .lg\:flex-col{
        flex-direction: column;
    }

    .lg\:justify-end{
        justify-content: flex-end;
    }

    .lg\:gap-x-12{
        -moz-column-gap: 3rem;
             column-gap: 3rem;
    }

    .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(4rem * var(--tw-space-x-reverse));
        margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .lg\:border-l{
        border-left-width: 1px;
    }

    .lg\:px-8{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .lg\:pr-8{
        padding-right: 2rem;
    }

    .lg\:pt-10{
        padding-top: 2.5rem;
    }

    .lg\:pt-4{
        padding-top: 1rem;
    }

    .lg\:text-2xl{
        font-size: 1.5rem;
        line-height: 1.415;
        letter-spacing: -0.017em;
    }
}
@media (min-width: 1280px){

    .xl\:-mx-6{
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }

    .xl\:min-h-\[700px\]{
        min-height: 700px;
    }

    .xl\:space-x-20 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(5rem * var(--tw-space-x-reverse));
        margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
    }
}
.\[\&\:has\(\[aria-selected\]\)\]\:bg-accent:has([aria-selected]){
    background-color: hsl(var(--accent));
}
.first\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-l-md:has([aria-selected]):first-child{
    border-top-left-radius: calc(var(--radius) - 2px);
    border-bottom-left-radius: calc(var(--radius) - 2px);
}
.last\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-r-md:has([aria-selected]):last-child{
    border-top-right-radius: calc(var(--radius) - 2px);
    border-bottom-right-radius: calc(var(--radius) - 2px);
}
.\[\&\:has\(svg\)\]\:pl-11:has(svg){
    padding-left: 2.75rem;
}
.\[\&\>div\:nth-last-of-type\(-n\+4\)\]\:pb-6>div:nth-last-of-type(-n+4){
    padding-bottom: 1.5rem;
}
@media not all and (min-width: 768px){

    .max-md\:\[\&\>div\:nth-last-of-type\(-n\+4\)\]\:mb-8>div:nth-last-of-type(-n+4){
        margin-bottom: 2rem;
    }

    .max-md\:\[\&\>div\:nth-last-of-type\(-n\+4\)\]\:rounded-b-3xl>div:nth-last-of-type(-n+4){
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
    }
}
@media (min-width: 768px){

    .md\:\[\&\>div\:nth-last-of-type\(1\)\]\:rounded-br-3xl>div:nth-last-of-type(1){
        border-bottom-right-radius: 1.5rem;
    }
}
.before\:\[\&\>div\:nth-last-of-type\(2\)\]\:border-b-2>div:nth-last-of-type(2)::before{
    content: var(--tw-content);
    border-bottom-width: 2px;
}
@media (min-width: 768px){

    .md\:\[\&\>div\:nth-last-of-type\(3\)\]\:rounded-bl-3xl>div:nth-last-of-type(3){
        border-bottom-left-radius: 1.5rem;
    }
}
@media not all and (min-width: 768px){

    .max-md\:\[\&\>div\:nth-of-type\(-n\+4\)\:nth-of-type\(n\+1\)\]\:rounded-t-3xl>div:nth-of-type(-n+4):nth-of-type(n+1){
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
    }
}
.\[\&\>div\:nth-of-type\(-n\+4\)\]\:py-6>div:nth-of-type(-n+4){
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
@media (min-width: 768px){

    .md\:\[\&\>div\:nth-of-type\(2\)\]\:rounded-tl-3xl>div:nth-of-type(2){
        border-top-left-radius: 1.5rem;
    }
}
.before\:\[\&\>div\:nth-of-type\(3\)\]\:border-t-2>div:nth-of-type(3)::before{
    content: var(--tw-content);
    border-top-width: 2px;
}
@media (min-width: 768px){

    .md\:\[\&\>div\:nth-of-type\(4\)\]\:rounded-tr-3xl>div:nth-of-type(4){
        border-top-right-radius: 1.5rem;
    }
}
.\[\&\>div\:nth-of-type\(4n\+1\)\]\:bg-transparent>div:nth-of-type(4n+1){
    background-color: transparent;
}
@media not all and (min-width: 768px){

    .max-md\:\[\&\>div\:nth-of-type\(4n\+2\)\]\:order-1>div:nth-of-type(4n+2){
        order: 1;
    }
}
.\[\&\>div\:nth-of-type\(4n\+3\)\]\:relative>div:nth-of-type(4n+3){
    position: relative;
}
.before\:\[\&\>div\:nth-of-type\(4n\+3\)\]\:pointer-events-none>div:nth-of-type(4n+3)::before{
    content: var(--tw-content);
    pointer-events: none;
}
.before\:\[\&\>div\:nth-of-type\(4n\+3\)\]\:absolute>div:nth-of-type(4n+3)::before{
    content: var(--tw-content);
    position: absolute;
}
.before\:\[\&\>div\:nth-of-type\(4n\+3\)\]\:-inset-px>div:nth-of-type(4n+3)::before{
    content: var(--tw-content);
    inset: -1px;
}
.before\:\[\&\>div\:nth-of-type\(4n\+3\)\]\:-z-10>div:nth-of-type(4n+3)::before{
    content: var(--tw-content);
    z-index: -10;
}
.before\:\[\&\>div\:nth-of-type\(4n\+3\)\]\:rounded-\[inherit\]>div:nth-of-type(4n+3)::before{
    content: var(--tw-content);
    border-radius: inherit;
}
.before\:\[\&\>div\:nth-of-type\(4n\+3\)\]\:border-x-2>div:nth-of-type(4n+3)::before{
    content: var(--tw-content);
    border-left-width: 2px;
    border-right-width: 2px;
}
.before\:\[\&\>div\:nth-of-type\(4n\+3\)\]\:border-purple-500>div:nth-of-type(4n+3)::before{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity));
}
@media not all and (min-width: 768px){

    .max-md\:\[\&\>div\:nth-of-type\(4n\+3\)\]\:order-2>div:nth-of-type(4n+3){
        order: 2;
    }

    .max-md\:\[\&\>div\:nth-of-type\(4n\+4\)\]\:order-3>div:nth-of-type(4n+4){
        order: 3;
    }

    .max-md\:\[\&\>div\:nth-of-type\(4n\+5\)\]\:hidden>div:nth-of-type(4n+5){
        display: none;
    }

    @media (min-width: 768px){

        .max-md\:md\:\[\&\>div\:nth-of-type\(n\)\]\:mb-0>div:nth-of-type(n){
            margin-bottom: 0px;
        }
    }
}
.\[\&\>div\]\:bg-slate-700\/20>div{
    background-color: rgb(51 65 85 / 0.2);
}
.\[\&\>svg\+div\]\:translate-y-\[-3px\]>svg+div{
    --tw-translate-y: -3px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\>svg\]\:absolute>svg{
    position: absolute;
}
.\[\&\>svg\]\:left-4>svg{
    left: 1rem;
}
.\[\&\>svg\]\:top-4>svg{
    top: 1rem;
}
.\[\&\>svg\]\:text-destructive>svg{
    color: hsl(var(--destructive));
}
.\[\&\>svg\]\:text-foreground>svg{
    color: hsl(var(--foreground));
}
.\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading]{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.\[\&_\[cmdk-group-heading\]\]\:py-1\.5 [cmdk-group-heading]{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.\[\&_\[cmdk-group-heading\]\]\:text-xs [cmdk-group-heading]{
    font-size: 0.75rem;
    line-height: 1.5;
}
.\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading]{
    font-weight: 500;
}
.\[\&_\[cmdk-group-heading\]\]\:text-muted-foreground [cmdk-group-heading]{
    color: hsl(var(--muted-foreground));
}
.\[\&_\[cmdk-group\]\:not\(\[hidden\]\)_\~\[cmdk-group\]\]\:pt-0 [cmdk-group]:not([hidden]) ~[cmdk-group]{
    padding-top: 0px;
}
.\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group]{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg{
    height: 1.25rem;
}
.\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg{
    width: 1.25rem;
}
.\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input]{
    height: 3rem;
}
.\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item]{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item]{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg{
    height: 1.25rem;
}
.\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg{
    width: 1.25rem;
}
.\[\&_\[role\=slider\]\]\:h-4 [role=slider]{
    height: 1rem;
}
.\[\&_\[role\=slider\]\]\:w-4 [role=slider]{
    width: 1rem;
}
.\[\&_p\]\:leading-relaxed p{
    line-height: 1.625;
}
.\[\&_span\]\:h-4 span{
    height: 1rem;
}

